import React, { useState, useEffect } from 'react';
import { Typography, Box, Paper, Grid, Button, Avatar, Snackbar, Alert, Container, Fab, Tabs, Tab, Select, MenuItem, ToggleButtonGroup, ToggleButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Chip } from '@mui/material';
import XIcon from '@mui/icons-material/X';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import axios from 'axios';
import serBackground from '../assets/ser_bg.jpg'; // Import the image
import { useTheme, useMediaQuery } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { styled } from '@mui/material/styles';
import CampaignTable from '../components/CampaignTable';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

// Scroll to Top Component
const ScrollTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <>
      {isVisible && 
        <Fab 
          color="primary" 
          size="small" 
          onClick={scrollToTop}
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            backgroundColor: '#ff004e',
            '&:hover': {
              backgroundColor: '#ff004e',
              opacity: 0.9,
            }
          }}
        >
          <KeyboardArrowUpIcon />
        </Fab>
      }
    </>
  );
};

const SER: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const { accountId, walletInterface } = useWalletInterface();
  const [twitterData, setTwitterData] = useState<any>(null);
  const [serScore, setSERScore] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const { isAuthenticated, setIsAuthenticated } = useAuth();

  useEffect(() => {
    if (accountId) {
      fetchUserData();
    }
  }, [accountId]);

  const fetchUserData = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      console.log('Fetching user data for accountId:', accountId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/${accountId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });
      console.log('User data received:', response.data);
      
      if (response.data.twitterData) {
        console.log('Setting Twitter data:', response.data.twitterData);
        setTwitterData(response.data.twitterData);
      } else {
        console.log('No Twitter data found');
        setTwitterData(null);
      }

      if (response.data.serScore) {
        setSERScore(response.data.serScore);
      }
    } catch (error: any) {
      console.error('Error fetching user data:', error);
      // Check if it's a 404 (user not found) error
      if (error.response?.status === 404) {
        // This is expected for new users, don't show error
        console.log('New user detected, no data yet');
        setTwitterData(null);
        setSERScore(null);
      } else {
        // For other errors, show the error message
        setError('Failed to fetch user data. Please try again later.');
      }
    }
  };


  const handleAuthenticate = async () => {
    if (!walletInterface) return;
    
    try {
      const token = await walletInterface.signMessage('Authenticate');
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/authenticate`, { token });
      
      if (response.data.success) {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
      }
    } catch (error) {
      console.error('Authentication failed:', error);
      setError('Failed to authenticate. Please try again.');
    }
  };

  const handleTwitterConnect = async () => {
    if (!walletInterface) {
      setError('Wallet not connected. Please connect your wallet first.');
      return;
    }

    if (!isAuthenticated) {
      setError('Please authenticate first before connecting to Twitter.');
      return;
    }

    try {
      const token = await walletInterface.signMessage('Connect Twitter');

      // Use the token to initiate Twitter authentication
      const width = 600;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;
      const popup = window.open(
        `${process.env.REACT_APP_API_URL}/auth/twitter?token=${token}`,
        'Twitter Connect',
        `width=${width},height=${height},left=${left},top=${top}`
      );

      window.addEventListener('message', async (event) => {
        if (event.origin !== process.env.REACT_APP_API_URL) return;
        if (event.data.type === 'TWITTER_AUTH_SUCCESS') {
          popup?.close();
          await fetchUserData();
        } else if (event.data.type === 'TWITTER_AUTH_FAILURE') {
          setError('Failed to connect Twitter. Please try again.');
          popup?.close();
        }
      }, false);
    } catch (error) {
      console.error('Error during Twitter connection:', error);
      setError('An error occurred while connecting to Twitter. Please try again.');
    }
  };

  const handleTwitterDisconnect = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      await axios.post(
        `${process.env.REACT_APP_API_URL}/auth/twitter/disconnect`,
        { accountId },
        {
          headers: { Authorization: `Bearer ${authToken}` }
        }
      );
      
      // Clear Twitter data from local state
      setTwitterData(null);
    } catch (error) {
      console.error('Error disconnecting Twitter:', error);
      setError('Failed to disconnect Twitter account');
    }
  };

  const handleCloseError = () => {
    setError(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${serBackground})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: isMobile ? 1 : 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontFamily: 'Styrene A Web, sans-serif',
        color: '#ff004e',
      }}
    >
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h1"
          gutterBottom
          sx={{
            fontSize: isMobile ? '2.5rem' : isTablet ? '3rem' : '4rem',
            textAlign: 'center',
            lineHeight: 1.4,
            fontWeight: '1000',
            marginTop: isMobile ? '1rem' : '2rem',
            fontFamily: 'Styrene A Web, sans-serif',
            color: '#ff004e',
          }}
        >
          <span style={{ color: '#ff004e', backgroundColor: 'white', padding: '0 2px', border: '0.5px solid #ff004e' }}>
            social
          </span>
          <br />
          <span style={{ color: '#ff004e', backgroundColor: 'white', padding: '0 2px', border: '0.5px solid #ff004e' }}>
            engagement
          </span>
          <br />
          <span style={{ color: '#ff004e', backgroundColor: 'white', padding: '0 2px', border: '0.5px solid #ff004e' }}>
            rewards
          </span>
        </Typography>

        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: isMobile ? 2 : 3, backgroundColor: 'white', border: '1px solid #ff004e', borderRadius: '8px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography 
                    variant={isMobile ? "body1" : "h6"} 
                    sx={{ 
                      fontWeight: 'bold', 
                      color: '#ff004e', 
                      textAlign: 'left',
                      fontSize: isMobile ? '1rem' : 'inherit'
                    }}
                  >
                    SER is an advanced tool that allows projects and brands to leverage their social media presence to earn rewards. 
                  </Typography>
                </Grid>
                <Grid 
                  item 
                  xs={12} 
                  md={4} 
                  sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    justifyContent: 'center',
                    paddingLeft: { xs: 0, md: 2 },
                    borderLeft: { xs: 'none', md: '1px solid #ff004e' },
                    borderTop: { xs: '1px solid #ff004e', md: 'none' },
                    paddingTop: { xs: 2, md: 0 },
                    marginTop: { xs: 2, md: 0 }
                  }}
                >
                  {!accountId ? (
                    <Typography sx={{ color: '#ff004e', textAlign: 'center', fontWeight: 'bold' }}>
                      Please connect your wallet to get started
                    </Typography>
                  ) : (
                    <>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{ 
                          backgroundColor: isAuthenticated ? 'rgba(255, 0, 78, 0.7)' : 'white',
                          borderColor: isAuthenticated ? 'rgba(255, 0, 78, 0.3)' : '#ff004e',
                          color: isAuthenticated ? 'rgba(255, 0, 78, 0.5)' : '#ff004e',
                          '&:hover': {
                            borderColor: isAuthenticated ? 'rgba(255, 0, 78, 0.3)' : '#d4003f',
                            backgroundColor: isAuthenticated ? 'rgba(255, 0, 78, 0.1)' : 'rgba(255, 0, 78, 0.04)',
                          },
                          mb: 1,
                          borderRadius: '10px',
                          transition: 'all 0.3s ease',
                        }}
                        onClick={handleAuthenticate}
                        startIcon={isAuthenticated ? <CheckCircleOutlineIcon /> : <LockPersonOutlinedIcon />}
                        disabled={isAuthenticated}
                      >
                        {isAuthenticated ? 'Authenticated' : 'Authenticate'}
                      </Button>
                      {accountId && (
                        <>
                          <Button
                            variant="outlined"
                            fullWidth
                            sx={{ 
                              backgroundColor: 'white',
                              borderColor: '#ff004e',
                              color: '#ff004e',
                              '&:hover': {
                                borderColor: '#d4003f',
                                backgroundColor: 'rgba(255, 0, 78, 0.04)',
                              },
                              mb: 1,
                              borderRadius: '10px',
                            }}
                            onClick={twitterData && twitterData.username ? handleTwitterDisconnect : handleTwitterConnect}
                            startIcon={<XIcon />}
                          >
                            {twitterData && twitterData.username ? 'Disconnect X' : 'Connect X'}
                          </Button>
                          {!twitterData?.username && (
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                display: 'block', 
                                color: 'rgba(255, 0, 78, 0.7)',
                                textAlign: 'center',
                                mt: 0.5,
                                fontSize: '0.75rem'
                              }}
                            >
                              Please enable pop-ups to connect your X account
                            </Typography>
                          )}
                          {twitterData && twitterData.username && (
                            <Box 
                              display="flex" 
                              alignItems="center" 
                              mt={1}
                              sx={{
                                padding: '10px',
                                borderRadius: '8px',
                                border: '1px solid rgba(255, 0, 78, 0.2)',
                                flexDirection: isMobile ? 'column' : 'row',
                                textAlign: isMobile ? 'center' : 'left',
                                gap: isMobile ? 2 : 0
                              }}
                            >
                              <Avatar 
                                src={twitterData.profile_image_url?.replace('_normal', '')}
                                sx={{ 
                                  mr: isMobile ? 0 : 2,
                                  width: isMobile ? 64 : 48,
                                  height: isMobile ? 64 : 48,
                                  border: '2px solid #ff004e'
                                }} 
                              />
                              <Box>
                                <Typography 
                                  sx={{ 
                                    color: '#ff004e', 
                                    fontWeight: 'bold',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: isMobile ? 'center' : 'flex-start',
                                    gap: 1
                                  }}
                                >
                                  {twitterData.displayName}
                                </Typography>
                                <Link
                                  href={`https://x.com/${twitterData.username}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: isMobile ? 'center' : 'flex-start',
                                    color: 'rgba(255, 0, 78, 0.7)',
                                    textDecoration: 'none',
                                    '&:hover': {
                                      color: '#ff004e',
                                      textDecoration: 'underline'
                                    }
                                  }}
                                >
                                  @{twitterData.username}
                                  <OpenInNewIcon sx={{ ml: 0.5, fontSize: 16 }} />
                                </Link>
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <CampaignTable />
          </Grid>
        </Grid>
      </Container>

      <ScrollTop />

      <Snackbar 
        open={!!error} 
        autoHideDuration={6000} 
        onClose={handleCloseError}
        anchorOrigin={{ 
          vertical: isMobile ? 'top' : 'bottom', 
          horizontal: 'center' 
        }}
      >
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%', color: '#ff004e', backgroundColor: 'white' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default SER;
