import React from 'react';
import { Typography, Box, Container, Paper, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import serBackground from '../assets/ser_bg.jpg';

const FAQ: React.FC = () => {
  const faqSections = [
    {
      title: "General",
      questions: [
        {
          question: "What is The Mouth?",
          answer: "THE AUDIENCE-OWNED MEDIA GROUP. The Mouth was born from the idea of transforming marketing by leveraging two innovative aspects of DLT: fractional ownership and microtransactions."
        },
      {
          question: "How are scores calculated?",
          answer: "Scores are calculated based on your interactions (e.g. likes, retweets, quote tweets) and adjusted through our scoring algorithm that rewards genuine interactions. Your base score can be amplified by token multipliers."
        },
        {
          question: "When can I claim my rewards?",
          answer: "Rewards can be claimed after a campaign ends, provided you met any token gating requirements during the campaign. The 'Claim' button will become active once rewards are available."
        }
      ]
    },
    {
      title: "SER",
      questions: [
        {
          question: "What is SER?",
          answer: ""
        },
        {
          question: "What does SER mean?",
          answer: "SER means Social Engagement Rewards, a The Mouth product. It is a playful term used in the crypto community, often associated with 'sir'."
        },
        {
            question: "How do campaigns work?",
            answer: "Campaigns are time-limited events where users can earn rewards by engaging with social channels of specific accounts. Each campaign has its own rules, rewards, and requirements."
          },

      ]
    },
    {
      title: "MOUTH Token",
      questions: [
        {
          question: "What is $MOUTH?",
          answer: "$MOUTH is the native token of The Mouth."
        },
        {
          question: "Where can I get $MOUTH?",
          answer: "You can acquire $MOUTH through: \n1. SaucerSwap DEX \n2. Participating in campaigns that offer $MOUTH rewards"
        },
        {
          question: "What are the benefits of holding $MOUTH?",
          answer: "Holding $MOUTH provides several benefits: \n- Score multipliers in campaigns \n- Access to exclusive campaigns \n- Governance participation \n- Platform fee reductions (coming soon)"
        }
      ]
    },
    {
      title: "The Loudspeaker",
      questions: [
        {
          question: "What is The Loudspeaker?",
          answer: "The Mouth's first product. A very effective and straightforward way to get a lot of real people talking about your project, brand or the topic of your choice on X. $MOUTH holders help curate and reward the best content."
        },
      ]
    }
  ];

  return (
    <Box sx={{
      backgroundImage: `url(${serBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundAttachment: 'fixed',
      minHeight: '100vh',
      py: 4
    }}>
      <Container maxWidth="md">
        <Paper elevation={3} sx={{ 
          p: 4, 
          backgroundColor: 'white', 
          borderRadius: '8px', 
          border: '1px solid #ff004e',
          position: 'relative',
          zIndex: 1
        }}>
          <Typography variant="h4" sx={{ color: '#ff004e', fontWeight: 'bold', mb: 4, textAlign: 'center' }}>
            Frequently Asked Questions
          </Typography>

          {faqSections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4 }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  color: '#ff004e', 
                  fontWeight: 'bold', 
                  mb: 2,
                  borderBottom: '2px solid rgba(255, 0, 78, 0.1)',
                  pb: 1
                }}
              >
                {section.title}
              </Typography>

              {section.questions.map((faq, index) => (
                <Accordion 
                  key={index}
                  sx={{
                    mb: 1,
                    border: '1px solid rgba(255, 0, 78, 0.1)',
                    '&:before': {
                      display: 'none',
                    },
                    '&.Mui-expanded': {
                      margin: '0 0 8px 0',
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: '#ff004e' }} />}
                    sx={{
                      '&.Mui-expanded': {
                        minHeight: '48px',
                        backgroundColor: 'rgba(255, 0, 78, 0.03)',
                      },
                    }}
                  >
                    <Typography sx={{ color: '#ff004e', fontWeight: 'bold' }}>
                      {faq.question}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'grey.700', whiteSpace: 'pre-line' }}>
                      {faq.answer}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Box>
          ))}
        </Paper>
      </Container>
    </Box>
  );
};

export default FAQ; 
