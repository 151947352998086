import Footer from './components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import NavBar from './components/Navbar';
import { Box, ThemeProvider } from '@mui/material';
import { AllWalletsProvider } from './services/wallets/AllWalletsProvider';
import AppRouter from './AppRouter';
import { theme } from './theme';
import "./App.css";
import { BrowserRouter as Router } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import MobileWarning from './components/MobileWarning';

function App() {
  return (
    <AuthProvider>
      <Router>
        <ThemeProvider theme={theme}>
          <AllWalletsProvider>
            <CssBaseline />
            <MobileWarning />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              <header>
                <NavBar />
              </header>
              <Box
                flex={1}
                p={0} // Remove padding to avoid background gaps
              >
                <AppRouter />
              </Box>
              {/* <Footer /> */}
            </Box>
          </AllWalletsProvider>
        </ThemeProvider>
      </Router>
    </AuthProvider>
  );
}

export default App;
