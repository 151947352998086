import { NetworkConfigs, NetworkName } from "./type"
import { HederaChainId } from "@hashgraph/hedera-wallet-connect";

const networkConfigs: NetworkConfigs = {
  testnet: {
    network: "testnet",
    jsonRpcUrl: "https://testnet.hashio.io/api",
    mirrorNodeUrl: "https://testnet.mirrornode.hedera.com",
    chainId: "0x128",
    walletConnectProjectId: 'f826b7ea5cbcc70a275f8152fded1a63',
    walletConnectChainId: HederaChainId.Testnet
  },
  mainnet: {
    network: "mainnet",
    jsonRpcUrl: "https://mainnet.hashio.io/api",
    mirrorNodeUrl: "https://mainnet-public.mirrornode.hedera.com",
    chainId: "0x127",
    walletConnectProjectId: '95eb0356f1d4d9d01d2696dfe7e2fb6a',
    walletConnectChainId: HederaChainId.Mainnet
  }
}

export const networkConfiguration = networkConfigs[(process.env.REACT_APP_HEDERA_NETWORK as NetworkName) ?? "testnet"];
