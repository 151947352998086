import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { 
  Box, FormControl, Select, MenuItem, Chip, ToggleButtonGroup, ToggleButton,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, SelectChangeEvent, Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Campaign, UserData, MongoObjectId } from '../types/Campaign';
import { useWalletInterface } from '../services/wallets/useWalletInterface';
import { getTimeLeft } from '../utils/timeUtils';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';
import { generateSlug } from '../utils/slugUtils';

const TabContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: '-1px',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    justifyContent: 'space-between',
  }
}));

const StyledTab = styled(Box)<{ active: boolean }>(({ active, theme }) => ({
  padding: '10px 20px',
  cursor: 'pointer',
  backgroundColor: active ? 'white' : '#ff004e',
  color: active ? '#ff004e' : 'white',
  border: '1px solid #ff004e',
  borderBottom: active ? 'none' : '1px solid #ff004e',
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
  marginRight: '5px',
  fontWeight: 'bold',
  position: 'relative',
  zIndex: active ? 1 : 0,
  fontSize: '14px',
  flex: 1,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  'data-active': active ? 'true' : 'false',
  [theme.breakpoints.down('sm')]: {
    padding: '8px 12px',
    fontSize: '12px',
    marginRight: '2px',
  },
  '&:hover': {
    backgroundColor: active ? 'white' : 'white',
    color: active ? '#ff004e' : '#ff004e',
  },
  '&::after': active ? {
    content: '""',
    position: 'absolute',
    bottom: -1,
    left: 0,
    right: 0,
    height: '2px',
    backgroundColor: 'white',
  } : {},
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  border: '1px solid #ff004e',
  borderRadius: '10px',
  overflow: 'hidden',
  backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    marginTop: '8px',
  },
  '& .MuiToggleButtonGroup-grouped': {
    margin: 1,
    border: 'none',
    borderRadius: '10px',
    padding: '5px 10px',
    color: '#ff004e',
    '&.Mui-selected': {
      backgroundColor: '#ff004e',
      color: 'white',
      '&:hover': {
        backgroundColor: '#ff004e',
      },
    },
    '&:not(:first-of-type)': {
      borderRadius: '10px',
    },
    '&:first-of-type': {
      borderRadius: '10px',
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)({
  '&.MuiToggleButton-root': {
    color: '#ff004e',
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 78, 0.04)',
    },
  },
});

const StyledSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#ff004e',
  },
  '& .MuiSelect-select': {
    color: '#ff004e',
    padding: '5px 32px 5px 10px', // Reduced vertical padding
  },
  '& .MuiSvgIcon-root': {
    color: '#ff004e',
  },
});

const StyledTableHeaderCell = styled(TableCell)({
  fontWeight: 'bold',
  // backgroundColor: '#ff004e',
  color: '#ff004e',
  borderBottom: '1px solid lightgray',
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '1px solid lightgrey',
  '&:last-child': {
    borderRight: 'none',
  },
  color: '#ff004e',
  fontWeight: 'bold',
}));

const LogoContainer = styled(Box)({
  width: '32px',
  height: '32px',
  marginRight: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  flexShrink: 0,
});

const CampaignLogo = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
  // borderRadius: '50%',
});

const NetworkLogo = styled('img')({
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  objectFit: 'contain',
  marginRight: 5,
  verticalAlign: 'middle',
  flexShrink: 0,
});

const StyledMenuItem = styled(MenuItem)({
  color: '#ff004e',
  '&:hover': {
    backgroundColor: 'rgba(255, 0, 78, 0.04)',
  },
  '&.Mui-selected': {
    backgroundColor: 'rgba(255, 0, 78, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 78, 0.12)',
    },
  },
});

const getCampaignId = (campaign: Campaign): string => {
  return typeof campaign._id === 'string' 
    ? campaign._id 
    : (campaign._id as MongoObjectId).$oid;
};

// Add interface for token price data
interface TokenPrice {
  id: string;
  priceUsd: number;
  decimals: number;
}

const CampaignTable: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeTab, setActiveTab] = useState('ALL CAMPAIGNS');
  const [network, setNetwork] = useState<string>('All Networks');
  const [prize, setPrize] = useState<string>('All Tokens');
  const [campaignType, setCampaignType] = useState<string>('all');
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [optedInCampaignIds, setOptedInCampaignIds] = useState<string[]>([]);
  const [optedInCampaigns, setOptedInCampaigns] = useState<Campaign[]>([]);
  const navigate = useNavigate();
  const { accountId } = useWalletInterface();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [tokenPrices, setTokenPrices] = useState<TokenPrice[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // 1. Get all campaigns
        console.log('Fetching campaigns...');
        const campaignsResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/campaigns`);
        const allCampaigns = campaignsResponse.data;
        console.log('Campaigns fetched successfully:', allCampaigns);
        setCampaigns(allCampaigns);

        // 2. If user is logged in, get their opted-in campaigns
        if (accountId) {
          console.log('Fetching user data for:', accountId);
          try {
            const authToken = localStorage.getItem('authToken');
            const userResponse = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/user/${accountId}`,
              {
                headers: { Authorization: `Bearer ${authToken}` }
              }
            );

            // 3. Extract just the IDs from the opted-in campaigns
            const userOptedInIds = userResponse.data.optedInCampaigns?.map(
              (campaign: any) => campaign._id
            ) || [];

            console.log('User opted-in campaign IDs:', userOptedInIds);

            // 4. Filter all campaigns to get only the ones user has opted into
            const userCampaigns = allCampaigns.filter((campaign: any) => {
              const campaignId = campaign._id;
              return userOptedInIds.includes(campaignId);
            });
            
            console.log('User campaigns filtered:', userCampaigns);
            setOptedInCampaigns(userCampaigns);
          } catch (error: any) {
            console.error('Error fetching user data:', error);
            if (error.response?.status === 404) {
              console.log('New user detected, setting empty opted-in campaigns');
              setOptedInCampaigns([]);
            } else {
              console.error('Other error fetching user data:', error);
              // Don't clear campaigns on user data error
              setError('Error loading user data, but campaigns are still visible');
            }
          }
        }
      } catch (error) {
        console.error('Error in campaign fetch:', error);
        // Don't clear campaigns immediately
        setError('Error loading campaigns. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    console.log('useEffect triggered with accountId:', accountId);
    fetchData();

    // Cleanup function to prevent state updates after unmount
    return () => {
      console.log('CampaignTable cleanup');
    };
  }, [accountId]);

  // Add effect to monitor campaigns state
  useEffect(() => {
    console.log('Campaigns state updated:', campaigns);
  }, [campaigns]);

  // Add useEffect to fetch token prices
  useEffect(() => {
    const fetchTokenPrices = async () => {
      try {
        const response = await fetch('https://api.saucerswap.finance/tokens');
        if (!response.ok) {
          throw new Error('Failed to fetch token prices');
        }
        const data = await response.json();
        setTokenPrices(data);
      } catch (error) {
        console.error('Error fetching token prices:', error);
        setError('Error loading token prices. USD values may be inaccurate.');
      }
    };

    fetchTokenPrices();
  }, []);

  const getFilteredCampaigns = () => {
    const campaignsToFilter = activeTab === 'PARTICIPATING' 
      ? optedInCampaigns 
      : campaigns;

    return campaignsToFilter.filter(campaign => {
      const networkMatch = network === 'All Networks' || campaign.network === network;
      const prizeMatch = prize === 'All Tokens' || 
        (campaign.prizes?.sharePrizes || []).some(p => 
          p.name === prize || 
          (prize === 'NFT' && p.type === 'nft') ||
          (prize === 'HBAR' && p.name === 'HBAR')
        );
      const typeMatch = campaignType === 'all' || campaign.type.toLowerCase() === campaignType;
      
      return networkMatch && prizeMatch && typeMatch;
    });
  };

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  const handleNetworkChange = (event: SelectChangeEvent<unknown>) => {
    setNetwork(event.target.value as string);
  };

  const handlePrizeChange = (event: SelectChangeEvent<unknown>) => {
    setPrize(event.target.value as string);
  };

  const handleCampaignTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newCampaignType: string | null,
  ) => {
    if (newCampaignType !== null) {
      setCampaignType(newCampaignType);
    }
  };

  const handleRowClick = (campaign: Campaign) => {
    const campaignId = typeof campaign._id === 'string' ? campaign._id : campaign._id.$oid;
    const slug = generateSlug(campaign.name);
    navigate(`/campaign/${slug}-${campaignId}`);
  };

  // Add function to calculate USD value
  const calculateUsdValue = (campaign: Campaign) => {
    if (campaign.type !== 'Share') return 0;
    if (!campaign.prizes?.sharePrizes?.length) return 0;

    return campaign.prizes.sharePrizes.reduce((total, prize) => {
      const tokenPrice = tokenPrices.find(tp => tp.id === prize.tokenId);
      if (!tokenPrice) return total;

      const usdValue = prize.amount * tokenPrice.priceUsd;
      return total + usdValue;
    }, 0);
  };

  const NoDataMessage = () => {
    console.log('Current state:', {
      activeTab,
      accountId,
      campaignsLength: campaigns.length,
      optedInCampaignsLength: optedInCampaigns.length,
      loading
    });

    return (
      <TableRow>
        <TableCell colSpan={6} align="center" sx={{ color: '#ff004e', py: 4 }}>
          {loading ? (
            'Loading campaigns...'
          ) : activeTab === 'PARTICIPATING' && !accountId ? (
            'Please connect your wallet to see your participating campaigns'
          ) : activeTab === 'PARTICIPATING' && optedInCampaigns.length === 0 ? (
            'You are not participating in any campaigns yet'
          ) : campaigns.length === 0 ? (
            'Error loading campaigns. Please refresh the page.'
          ) : (
            'No campaigns match the current filters'
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <Box>
      <TabContainer>
        <StyledTab 
          active={activeTab === 'ALL CAMPAIGNS'} 
          onClick={() => handleTabChange('ALL CAMPAIGNS')}
        >
          ALL
          </StyledTab>
  {/* Temporarily removed ELIGIBLE tab
  <StyledTab 
    active={activeTab === 'ELIGIBLE'} 
    onClick={() => handleTabChange('ELIGIBLE')}
  >
    ELIGIBLE
  </StyledTab>
  */}
        <StyledTab 
          active={activeTab === 'PARTICIPATING'} 
          onClick={() => handleTabChange('PARTICIPATING')}
        >
          PARTICIPATING
        </StyledTab>
      </TabContainer>
      <Paper sx={{ 
        p: 2,
        pt: 1,
        backgroundColor: 'white', 
        border: '1px solid #ff004e', 
        borderRadius: '8px', 
        borderTopLeftRadius: 0,
        color: '#ff004e',
      }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell>CAMPAIGN</StyledTableCell>
                <StyledTableCell>TIME LEFT</StyledTableCell>
                <StyledTableCell>PRIZE</StyledTableCell>
                <StyledTableCell>CHANNELS</StyledTableCell>
                <StyledTableCell>PARTICIPANTS</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <NoDataMessage />
              ) : getFilteredCampaigns().length > 0 ? (
                getFilteredCampaigns().map((campaign) => (
                  <TableRow 
                    key={getCampaignId(campaign)} 
                    onClick={() => handleRowClick(campaign)}
                    sx={{ 
                      cursor: 'pointer', 
                      '&:hover': { backgroundColor: 'rgba(255, 0, 78, 0.04)' },
                    }}
                  >
                    <StyledTableCell>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'center',
                        gap: 1,
                      }}>
                        {/* Temporarily removed logo section
                        <LogoContainer>
                          <CampaignLogo 
                            src={`/campaign-logos/${campaign._id}.png`} 
                            onError={(e) => {
                              e.currentTarget.src = "/campaign-logos/default.png";
                            }}
                            alt={campaign.name} 
                          />
                        </LogoContainer>
                        */}
                        <Box>
                          <Typography 
                            variant="body1" 
                            sx={{ 
                              fontWeight: 'bold',
                              lineHeight: 1.2,
                              marginBottom: '2px'
                            }}
                          >
                            {campaign.name}
                          </Typography>
                          <Box sx={{ 
                            display: 'flex', 
                            alignItems: 'center'
                          }}>
                            <NetworkLogo 
                              src={`/network-logos/${campaign.network?.toLowerCase() || 'default'}.png`} 
                              alt={campaign.network || 'Unknown Network'} 
                            />
                            <Typography 
                              variant="body2"
                              sx={{ 
                                fontSize: '0.875rem',
                                color: 'inherit'
                              }}
                            >
                              {campaign.network || 'Unknown Network'}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{getTimeLeft(campaign.endTime)}</StyledTableCell>
                    <StyledTableCell>
                      {campaign.totalPrize} 
                      <br />
                      (${calculateUsdValue(campaign).toFixed(2)})
                    </StyledTableCell>

                    <StyledTableCell>{campaign.channels.join(', ')}</StyledTableCell>
                    <StyledTableCell>{campaign.participants}</StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <NoDataMessage />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default CampaignTable;
