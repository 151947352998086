// Export the MongoDB types
export interface MongoNumber {
  $numberDouble?: string;
  $numberInt?: string;
  $numberDecimal?: string;
}

export interface Tweet {
  id: string;
  url: string;
  createdAt: string;
  likes: number;
  retweets: number;
  impressions: number;
  updatedAt: string;
  content?: string;
  _id?: string;
}

export interface UserInteraction {
  url: string;
  content: string;
  createdAt: string;
  originalTweetId: string;
  interactionType: 'reply' | 'quote' | 'retweet';
  speedPoints: number;
  qualityPoints: number;
  viewPoints: number;
  timeDelta: number;
  originalTweetMultiplier: number;
  updatedAt: string;
}

export interface PrizePot {
  name: string;
  tokenId: string;
  amount: number;
  type: 'nft' | 'token';
  decimals: number;
  _id: string;
}

export interface TokenTier {
  amount: number;
  multiplier: string | number;
}

export interface BaseMultiplier {
  name: string;
  symbol: string;
  tokenId: string;
  tiers?: {
    amount: number;
    multiplier: number;
  }[];
}

export interface NFTMultiplier extends BaseMultiplier {
  type: 'nft';
  amount: number;
  multiplier: number;
}

export interface FungibleMultiplier extends BaseMultiplier {
  type: 'fungible';
  decimals: number;
}

export interface LPMultiplier extends BaseMultiplier {
  type: 'lp';
}

export type Multiplier = FungibleMultiplier | NFTMultiplier | LPMultiplier;

// Type guard functions
export function isLPMultiplier(multiplier: Multiplier): multiplier is LPMultiplier {
  return multiplier.type === 'lp';
}

export function isFungibleMultiplier(multiplier: Multiplier): multiplier is FungibleMultiplier {
  return multiplier.type === 'fungible';
}

export interface CampaignContent {
  tweets: Tweet[];
}

export interface UserScore {
  list_id: { $numberInt: string };
  repliesProcessed: { $numberInt: string };
  retweetsProcessed: { $numberInt: string };
  quotesProcessed: { $numberInt: string };
  speedPoints: { $numberDouble: string };
  qualityPoints: { $numberDouble: string };
  viewPoints: { $numberDouble: string };
  score: { $numberDouble: string };
  updatedAt: string;
}

export interface UserCampaignData {
  interactions: UserInteraction[];
  scores: Array<{ score: number; timestamp: Date }>;
  multiplier?: number;
  prizes?: {
    share: Array<{
      token_name: string;
      token_id: string;
      amount: number;
    }>;
  };
  // Add new fields for per-token claims
  claims?: {
    [tokenId: string]: 'unclaimed' | 'pending' | 'claimed';
  };
  claimTxs?: {
    [tokenId: string]: {
      txId: string;
      timestamp: Date;
      amount: number;
    };
  };
  // Keep legacy fields for backward compatibility
  claimStatus?: 'unclaimed' | 'pending' | 'claimed';
  claimTxId?: string;
  claimTimestamp?: Date;
}

export interface MongoObjectId {
  $oid: string;
}

export interface UserData {
  _id: MongoObjectId;
  accountId: string;
  __v: number;
  optedInCampaigns: Array<MongoObjectId | string>;
  campaigns_data: {
    [key: string]: UserCampaignData;
  };
  socials: {
    twitter?: {
      lastUpdated: { $date: { $numberLong: string } };
      withheldInCountries: string[];
    };
  };
}

export interface Prize {
  name: string;
  tokenId: string;
  amount: number;
  type: string;
  decimals: number;
  customFee?: number;
  claimStatus?: 'claimed' | 'unclaimed';
  claimTx?: {
    txId: string;
    timestamp: string;
    amount: number;
  };
}

export interface TwitterData {
  limits: {
    tweetsProcessed: number;
    postsLimit: number;
  };
  autoTweetAdd: boolean;
  twitterList: string[];
  creatorTwitterHandle: string;
}

export interface Campaign {
  _id: string | MongoObjectId;
  name: string;
  type: string;
  description: string;
  network: string;
  endTime: string | {
    $date: {
      $numberLong: string;
    };
  };
  tokenGating: Array<{
    name: string;
    tokenId: string;
    amount: number;
    type: string;
    _id: string;
  }>;
  prizePot: Array<{
    name: string;
    tokenId: string;
    amount: number;
    type: string;
    decimals?: string;
    _id: { $oid: string };
    customFee?: number;
  }>;
  channels: string[];
  multipliers: Multiplier[];
  participants: number;
  totalPrize: string;
  claimAvailable: boolean;
  status: 'active' | 'finished';
  maxMultiplier: string;
  twitterData: TwitterData & {
    creatorTwitterHandle: string;
  };
  prizes: {
    sharePrizes: Prize[];
  };
  content?: {
    tweets: Array<{
      id: string;
      url: string;
      createdAt: string;
      likes: number;
      retweets: number;
      impressions: number;
      updatedAt: string;
    }>;
  };
  metrics?: {
    twitter?: {
      replies: number;
      retweets: number;
      quotes: number;
      impressions: number;
      speedPoints: number;
      qualityPoints: number;
      viewPoints: number;
      score: number;
      lastUpdated: string;
    };
  };
  limits?: {
    postsLimit: number;
  };
  updatedAt?: {
    $date: {
      $numberLong: string;
    };
  };
  completedAt?: {
    $date: {
      $numberLong: string;
    };
  };
}
