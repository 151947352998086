import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState, useEffect } from 'react';

const MobileWarning = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  if (!isMobile || !isVisible) return null;

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: '#ff004e',
        color: 'white',
        zIndex: 9999,
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Typography variant="body1" sx={{ flex: 1 }}>
        This app is optimized for desktop use. Some features may not work correctly on mobile devices.
      </Typography>
      <IconButton 
        size="small" 
        onClick={() => setIsVisible(false)}
        sx={{ color: 'white', ml: 1 }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default MobileWarning;
