import React, { useState } from 'react';
import { Box, Typography, Grid, Paper, Chip, LinearProgress, Tooltip, Button, Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReplyIcon from '@mui/icons-material/Reply';
import RepeatIcon from '@mui/icons-material/Repeat';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Tweet, UserInteraction, MongoNumber } from '../types/Campaign';
import { formatDistanceToNow, format } from 'date-fns';

interface CampaignTweetsProps {
  tweets: Tweet[];
  userInteractions: UserInteraction[];
  accountId: string | null;
}

const TweetCard = styled(Box)(({ theme }) => ({
  position: 'relative',
  marginBottom: '16px',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    border: '2px solid transparent',
    borderRadius: '8px',
    transition: 'border-color 0.3s ease',
    pointerEvents: 'none',
    zIndex: 1
  },
  '&.completed::after': {
    borderColor: '#ff004e'
  }
}));

const StyledPaper = styled(Paper)({
  padding: '16px',
  border: '1px solid #ff004e',
  borderRadius: '8px',
  position: 'relative',
  zIndex: 2
});

const PointsChipStyled = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  marginLeft: '8px',
  padding: '0 8px',
  height: '20px',
  fontSize: '0.75rem',
  borderRadius: '10px',
  backgroundColor: 'white',
  color: '#ff004e'
});

const StyledTypography = styled(Typography)({
  color: '#ff004e'
}) as typeof Typography;

const TweetContent = styled(Box)({
  padding: '12px',
  marginBottom: '16px',
  backgroundColor: 'rgba(255, 0, 78, 0.05)',
  borderRadius: '8px',
  border: '1px solid rgba(255, 0, 78, 0.1)'
});

const getNumericValue = (value: number | MongoNumber): number => {
  if (typeof value === 'number') return value;
  if (value.$numberDouble) return parseFloat(value.$numberDouble);
  if (value.$numberInt) return parseInt(value.$numberInt);
  if (value.$numberDecimal) return parseFloat(value.$numberDecimal);
  return 0;
};

const calculatePoints = (interaction: UserInteraction): number => {
  return getNumericValue(interaction.speedPoints) + 
         getNumericValue(interaction.qualityPoints) + 
         getNumericValue(interaction.viewPoints);
};

const CampaignTweets: React.FC<CampaignTweetsProps> = ({ tweets, userInteractions, accountId }) => {
  const [page, setPage] = useState(1);
  const tweetsPerPage = 3;

  console.log('Tweet data:', tweets);

  const getInteractionForTweet = (tweetId: string, type: string) => {
    return userInteractions.find(interaction => 
      interaction.originalTweetId === tweetId && 
      interaction.interactionType === type
    );
  };

  const getInteractionUrl = (tweet: Tweet, type: string) => {
    switch(type) {
      case 'reply':
        return `https://twitter.com/intent/tweet?in_reply_to=${tweet.id}`;
      case 'retweet':
        return `https://twitter.com/intent/retweet?tweet_id=${tweet.id}`;
      case 'quote':
        return `https://twitter.com/intent/tweet?url=${encodeURIComponent(tweet.url)}`;
      default:
        return tweet.url;
    }
  };

  const getLastUpdateTime = (tweetId: string): string | null => {
    const tweetInteractions = userInteractions.filter(
      interaction => interaction.originalTweetId === tweetId
    );
    
    if (tweetInteractions.length === 0) return null;
    
    const lastUpdate = tweetInteractions.reduce((latest, current) => {
      const currentDate = new Date(current.updatedAt);
      const latestDate = new Date(latest.updatedAt);
      return currentDate > latestDate ? current : latest;
    });

    return formatDistanceToNow(new Date(lastUpdate.updatedAt), { addSuffix: true });
  };

  // Sort tweets by createdAt in descending order
  const sortedTweets = [...tweets].sort((a, b) => 
    new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  // Calculate pagination
  const totalPages = Math.ceil(sortedTweets.length / tweetsPerPage);
  const paginatedTweets = sortedTweets.slice(
    (page - 1) * tweetsPerPage,
    page * tweetsPerPage
  );

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
    // Scroll to top of tweets section
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const isOlderThanSevenDays = (date: string) => {
    const tweetDate = new Date(date);
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    return tweetDate < sevenDaysAgo;
  };

  return (
    <Box>
      

      {paginatedTweets.map((tweet) => {
        const completedInteractions = ['reply', 'retweet', 'quote'].filter(
          type => getInteractionForTweet(tweet.id, type)
        );
        const isFullyCompleted = completedInteractions.length === 3;
        const totalPoints = completedInteractions.reduce((sum, type) => {
          const interaction = getInteractionForTweet(tweet.id, type);
          return sum + (interaction ? calculatePoints(interaction) : 0);
        }, 0);
        const lastUpdateTime = getLastUpdateTime(tweet.id);
        const isTweetExpired = isOlderThanSevenDays(tweet.createdAt);

        return (
          <TweetCard key={tweet.id} className={isFullyCompleted ? 'completed' : ''}>
            <StyledPaper
              onClick={() => window.open(tweet.url, '_blank')}
              sx={{ 
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: 'rgba(255, 0, 78, 0.02)'
                }
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
                    <Box>
                      <StyledTypography variant="h6" sx={{ fontWeight: 'bold' }}>
                        X post - {format(new Date(tweet.createdAt), 'MMM d, yyyy')}
                      </StyledTypography>
                    </Box>
                    {isFullyCompleted && (
                      <Chip
                        icon={<CheckCircleIcon />}
                        label={`All completed! +${Math.round(totalPoints)} pts`}
                        sx={{ 
                          backgroundColor: '#ff004e',
                          color: 'white',
                          '& .MuiChip-icon': { color: 'white' }
                        }}
                      />
                    )}
                  </Box>

                  <TweetContent>
                    <StyledTypography 
                      variant="body1" 
                      sx={{ 
                        whiteSpace: 'pre-wrap',
                        wordBreak: 'break-word'
                      }}
                    >
                      {tweet.content}
                    </StyledTypography>
                  </TweetContent>

                  <Box sx={{ display: 'flex', gap: 2, mt: 1, mb: 2 }}>
                    <StyledTypography variant="body2">
                      {`❤️ ${tweet.likes}`}
                    </StyledTypography>
                    <StyledTypography variant="body2">
                      {`🔄 ${tweet.retweets}`}
                    </StyledTypography>
                    <StyledTypography variant="body2">
                      {`👁️ ${tweet.impressions}`}
                    </StyledTypography>
                  </Box>

                  {isTweetExpired && (
                    <Box sx={{ 
                      mt: 2, 
                      mb: 2,
                      p: 1.5,
                      backgroundColor: 'rgba(255, 0, 78, 0.05)',
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 0, 78, 0.2)'
                    }}>
                      <StyledTypography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        ⏰ This tweet is over 7 days old. New interactions will not be counted for points.
                      </StyledTypography>
                    </Box>
                  )}

                  {accountId && (
                    <>
                      <Box sx={{ mt: 2 }}>
                        {['reply', 'retweet', 'quote'].map((type) => {
                          const interaction = getInteractionForTweet(tweet.id, type);
                          const points = interaction ? calculatePoints(interaction) : 0;

                          return (
                            <Tooltip
                              key={type}
                              title={isTweetExpired ? 
                                "This tweet is too old for new interactions to be counted" :
                                (interaction ? 
                                  `Earned ${Math.round(points)} points! Speed: ${Math.round(getNumericValue(interaction.speedPoints))}, Quality: ${Math.round(getNumericValue(interaction.qualityPoints))}, Views: ${Math.round(getNumericValue(interaction.viewPoints))}` : 
                                  `Click to ${type} this tweet`
                                )
                              }
                              arrow
                              placement="top"
                            >
                              <span> {/* Wrap in span for tooltip on disabled button */}
                                <Button
                                  variant="outlined"
                                  startIcon={
                                    type === 'reply' ? <ReplyIcon /> :
                                    type === 'retweet' ? <RepeatIcon /> :
                                    <FormatQuoteIcon />
                                  }
                                  endIcon={interaction && <CheckCircleIcon />}
                                  disabled={isTweetExpired && !interaction}
                                  sx={{
                                    color: interaction ? 'white' : '#ff004e',
                                    backgroundColor: interaction ? '#ff004e' : 'transparent',
                                    border: `1px solid ${interaction ? 'transparent' : '#ff004e'}`,
                                    '&:hover': {
                                      backgroundColor: interaction ? '#ff004e' : 'rgba(255, 0, 78, 0.1)',
                                      border: '1px solid #ff004e',
                                    },
                                    marginRight: '8px',
                                    marginBottom: '8px',
                                    position: 'relative',
                                    zIndex: 3,
                                    cursor: 'pointer',
                                    pointerEvents: 'auto',
                                    '&.Mui-disabled': {
                                      opacity: 0.6,
                                      border: '1px solid rgba(255, 0, 78, 0.3)',
                                      color: 'rgba(255, 0, 78, 0.5)',
                                    }
                                  }}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    console.log('Button clicked!', {
                                      type,
                                      tweetId: tweet.id,
                                      url: getInteractionUrl(tweet, type)
                                    });
                                    const url = getInteractionUrl(tweet, type);
                                    window.open(url, '_blank');
                                  }}
                                >
                                  {type.charAt(0).toUpperCase() + type.slice(1)}
                                  {interaction && (
                                    <PointsChipStyled>
                                      {Math.round(points)} pts
                                    </PointsChipStyled>
                                  )}
                                </Button>
                              </span>
                            </Tooltip>
                          );
                        })}
                      </Box>
                      
                      {/* {lastUpdateTime && (
                        <StyledTypography 
                          variant="caption" 
                          sx={{ 
                            display: 'block', 
                            mt: 2, 
                            opacity: 0.8 
                          }}
                        >
                          Daily interaction check - last update {lastUpdateTime}
                        </StyledTypography>
                      )} */}
                    </>
                  )}
                </Grid>
              </Grid>
            </StyledPaper>
          </TweetCard>
        );
      })}

      {totalPages > 1 && (
        <Box 
          sx={{ 
            display: 'flex', 
            justifyContent: 'center',
            mt: 4,
            mb: 2
          }}
        >
          <Pagination 
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            size="large"
            sx={{
              '& .MuiPaginationItem-root': {
                color: '#ff004e',
                borderColor: '#ff004e',
                '&.Mui-selected': {
                  backgroundColor: '#ff004e',
                  color: 'white',
                  '&:hover': {
                    backgroundColor: '#d3003f',
                  },
                },
                '&:hover': {
                  backgroundColor: 'rgba(255, 0, 78, 0.04)',
                },
              },
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CampaignTweets;