import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import SER from "./pages/SER";
import Tiles from "./pages/Tiles";
import Loudspeaker from "./pages/Loudspeaker";
import HBARVote from "./pages/HBARVote";
import Bounties from "./pages/Bounties";
import CampaignDetails from "./pages/CampaignDetails";
import FAQ from "./pages/FAQ";
export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />}>
        <Route index element={<SER />} />
        <Route path="ser" element={<SER />} />
        <Route path="tiles" element={<Tiles />} />
        <Route path="loudspeaker" element={<Loudspeaker />} />
        <Route path="hbarvote" element={<HBARVote />} />
        <Route path="bounties" element={<Bounties />} />
        <Route path="campaign/:slugId" element={<CampaignDetails />} />
        <Route path="faq" element={<FAQ />} />
      </Route>
    </Routes>
  );
}
